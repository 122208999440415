@import "variable";
@import "fonts";

@import "structure";
@import "typography";
@import "helpers";
@import "material-components";
@import 'ngx-toastr/toastr';


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
