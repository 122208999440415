body {
  .mat-button-toggle,
  .mat-button-base,
  .mat-button,
  .mat-raised-button,
  .mat-fab,
  .mat-icon-button,
  .mat-mini-fab,
  .mat-card,
  .mat-checkbox,
  .mat-input-container,
  .mat-list,
  .mat-menu-item,
  .mat-radio-button,
  .mat-select,
  .mat-list .mat-list-item .mat-list-item-content,
  .mat-nav-list .mat-list-item .mat-list-item-content,
  .mat-simple-snackbar,
  .mat-tab-label,
  .mat-slide-toggle-content,
  .mat-toolbar,
  .mat-tooltip,
  .mat-option,
  .mat-list .mat-subheader,
  .mat-nav-list .mat-subheader,
  .mat-paginator,
  .mat-paginator-page-size .mat-select-trigger {
    font-family: $main-ff !important;
  }
  [md-button],
  [md-fab],
  [md-icon-button],
  [md-mini-fab],
  [md-raised-button] {
    font-weight: inherit;
  }
  .md-button-toggle-label {
    margin-bottom: 0;
  }

  .mat-card {
    .mat-card-header {
      margin-bottom: $module;
    }
  }
  .mat-card-avatar {
    overflow: hidden;
  }
  .mat-card-image {
    max-width: none;
  }
  .mat-card-title {
    margin-bottom: .5rem;
  }
  .mat-card-subtitle {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .mat-form-field {
    display: block;
    line-height: inherit;

    .mat-input-prefix {
      padding-right: ($module / 2);
    }
    .mat-input-suffix {
      padding-left: ($module / 2);
    }
  }
  & .rtl {
    .mat-form-field {
      .mat-input-prefix {
        padding-left: ($module / 2);
        padding-right: 0;
      }
      .mat-input-suffix {
        padding-left: 0;
        padding-right: ($module / 2);
      }
    }
  }
  .mat-input-container {
    width: 100%;
  }
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    > .mat-icon {
      vertical-align: bottom;
    }
  }
  .mat-list {
    .mat-list-item {
      color: inherit;

      .mat-list-item-content {
        padding: 0 !important;

        .mat-icon.mat-list-avatar {
          font-size: 40px;
        }
        h1, h2, h3, h4, h5, h6 {
          font-weight: $headers-fw;
          line-height: 1.3;
          overflow: hidden;
        }
      }
    }
  }

  .mat-progress-bar,
  .mat-progress-circle,
  .mat-spinner {
    margin-bottom: $module;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .mat-radio-group {
    display: block;
    margin: 0 -11px $module;

    &:last-child {
      margin-bottom: 0;
    }

    .mat-radio-button {
      margin: 0 11px;

      .mat-radio-label {
        margin: 0;
      }
    }
  }
  .column-layout {
    .mat-radio-button {
      display: block;
      margin-bottom: $module;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .mat-checkbox {
    display: inline-block;
    margin-bottom: $module;
    vertical-align: top;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .mat-checkbox-layout {
    margin-bottom: 0;
  }

  .mat-option {
    font-size: inherit !important;
  }

  .mat-tab-label {
    .mat-icon {
      vertical-align: middle;
    }
  }
  .mat-tab-body-content {
    overflow: auto;
  }
  .mat-icon {
    position: relative;
  }
  .sb-item-head {
    font-weight: $headers-fw;
  }
  .mat-paginator .mat-paginator-page-size-select {
    margin: 0 4px;
    width: 56px;
  }
  .mat-mdc-form-field {
    display: block;
  }
}
