@import 'variable';

.overlay {
  background: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 998;
}
.loader {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 999;

  .dot {
    animation: slide 1s infinite;
    background: main-color(A400);
    border-radius: 100%;
    display: inline-block;
    height: 24px;
    width: 24px;

    &:nth-child(1) {
      animation-delay: 0.1s;
      background: main-color(A100);
    }
    &:nth-child(2) {
      animation-delay: 0.2s;
      background: main-color(900);
    }
    &:nth-child(3) {
      animation-delay: 0.3s;
      background: main-color(700);
    }
    &:nth-child(4) {
      animation-delay: 0.4s;
      background: main-color(500);
    }
    &:nth-child(5) {
      animation-delay: 0.5s;
      background: main-color(300);
    }
  }
}
@keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}